import React,{Component} from 'react';
import {GallerySection,Heading,GalleryInnerContainer,GalleryInnerContainerBig
} from './gallery.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

class Gallery extends Component {
   
    openDialogBox(imageArray,idx)
    {
        this.props.openLightBox(imageArray,idx);
    }

    render() {
        return (
            <GallerySection id="gallerySection">
                <Container>
                    <Heading>
                        {this.props.GalleryData.Heading}
                    </Heading>
                    <Row>
                        <Col md={4}>
                            <GalleryInnerContainer onClick={this.openDialogBox.bind(this,this.props.GalleryData,0)}>
                                <GatsbyImage
                                    image={this.props.GalleryData.Slider[0].GallerySliderImg.childImageSharp.gatsbyImageData}
                                    className="GallerySliderImg"
                                    alt="" />
                            </GalleryInnerContainer>
                        </Col>
                        <Col md={4}>
                            <GalleryInnerContainer onClick={this.openDialogBox.bind(this,this.props.GalleryData,1)}>
                                <GatsbyImage
                                    image={this.props.GalleryData.Slider[1].GallerySliderImg.childImageSharp.gatsbyImageData}
                                    className="GallerySliderImg"
                                    alt="" />
                            </GalleryInnerContainer>
                        </Col>
                        <Col md={4}>
                            <GalleryInnerContainer onClick={this.openDialogBox.bind(this,this.props.GalleryData,2)}>
                                <GatsbyImage
                                    image={this.props.GalleryData.Slider[2].GallerySliderImg.childImageSharp.gatsbyImageData}
                                    className="GallerySliderImg"
                                    alt="" />
                            </GalleryInnerContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <GalleryInnerContainer onClick={this.openDialogBox.bind(this,this.props.GalleryData,3)}>
                                <GatsbyImage
                                    image={this.props.GalleryData.Slider[3].GallerySliderImg.childImageSharp.gatsbyImageData}
                                    className="GallerySliderImg"
                                    alt="" />
                            </GalleryInnerContainer>
                            <Row>
                                <Col md={6}>
                                    <GalleryInnerContainer onClick={this.openDialogBox.bind(this,this.props.GalleryData,4)}>
                                        <GatsbyImage
                                            image={this.props.GalleryData.Slider[4].GallerySliderImg.childImageSharp.gatsbyImageData}
                                            className="GallerySliderImg"
                                            alt="" />
                                    </GalleryInnerContainer>
                                </Col>
                                <Col md={6}>
                                    <GalleryInnerContainer onClick={this.openDialogBox.bind(this,this.props.GalleryData,5)}>
                                        <GatsbyImage
                                            image={this.props.GalleryData.Slider[5].GallerySliderImg.childImageSharp.gatsbyImageData}
                                            className="GallerySliderImg"
                                            alt="" />
                                    </GalleryInnerContainer>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <GalleryInnerContainerBig onClick={this.openDialogBox.bind(this,this.props.GalleryData,6)}>
                                <GatsbyImage
                                    image={this.props.GalleryData.Slider[6].GallerySliderImg.childImageSharp.gatsbyImageData}
                                    className="GallerySliderImg"
                                    alt="" />
                            </GalleryInnerContainerBig>
                        </Col>

                    </Row>
                </Container>
            </GallerySection>
        );
    }
}

export default props => (
<StaticQuery
    query={graphql`{
  realestatePage4Json {
    Gallery {
      Heading
      Slider {
        GallerySliderImg {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
          publicURL
        }
      }
    }
  }
}
`}
    render={(data) => (
        <Gallery 
        GalleryData={data.realestatePage4Json.Gallery}
        {...props}
        />
    )}
/>
)
