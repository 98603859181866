import styled from 'styled-components';
import {device} from '../Common/device';
import {SectionHeading} from '../Common/common.style';

export const GallerySection = styled.section`
    padding:100px 0px 70px;

    @media ${device.tablet} {
        padding:80px 10px 50px;
    }

`;

export const Heading = styled(SectionHeading)`
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const GallerySliderCol = styled.div`
`;

export const GalleryInnerContainer = styled.div`
    cursor:pointer;
    overflow: hidden;
    margin-bottom:30px;

    .GallerySliderImg{
        height:210px;
        backface-visibility: hidden;
        -webkit-transform: scale(1);
        transform: scale(1);
        transition: transform .3s ease-in-out !important;
        -webkit-transition: -webkit-transform .3s ease-in-out !important;

        :hover{
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
        }

        @media ${device.tablet} {
            width:100%;
        }
    }
`;
export const GalleryInnerContainerBig = styled.div`
    cursor:pointer;
    overflow: hidden;
    margin-bottom:30px;

    .GallerySliderImg{
        height:450px;
        backface-visibility: hidden;
        -webkit-transform: scale(1);
        transform: scale(1);
        transition: transform .3s ease-in-out !important;
        -webkit-transition: -webkit-transform .3s ease-in-out !important;

        :hover{
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
        }
        
        @media ${device.tablet} {
            width:100%;
            height:210px;
        }
    }
`;

