import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {AmenitiesSection,
    Heading,
    AmenitiesLayout,
    AmenitiesImage
} from './amenities.style';

import { useStaticQuery, graphql } from 'gatsby';

const Amenities = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
            realestatePage4Json{
                Amenities{
                    Heading
                    AmenitiesData{
                        Img
                        AmenitiesText
                    }
                }
            }
        }
    `);

    const AmenitiesData = JSONData.realestatePage4Json.Amenities;
    
    return(
        <AmenitiesSection id="amenitiesSection">
            <Container>
                <Row>
                    <Col md={12}>
                        <Heading>
                        {AmenitiesData.Heading}
                        </Heading>
                    </Col>
                </Row>
                <Row>
                {
                    AmenitiesData.AmenitiesData.map((amnObj, idx) => {
                    return <Col lg={3} md={6} sm={6} xs={12}>
                        <AmenitiesLayout>
                            <AmenitiesImage src={amnObj.Img} alt="" />
                            <h5>
                            {amnObj.AmenitiesText}
                            </h5>
                        </AmenitiesLayout>
                    </Col>
                    })
                }
                </Row>
            </Container>
        </AmenitiesSection>

    );
}

export default Amenities;