import styled from 'styled-components';
import {SectionHeading} from '../Common/common.style';
import {device} from '../Common/device';
import {TabList} from 'react-tabs';
import {ChevronLeft} from '@styled-icons/boxicons-regular/ChevronLeft';
import {ChevronRight} from '@styled-icons/boxicons-regular/ChevronRight';
import {Row} from 'react-bootstrap';

export const PropertiesSection = styled.section`
    padding:100px 0px;
    background:#f5f5f5;

    @media ${device.tablet}{
        padding:80px 10px;
    }

    .slick-list{
        line-height:0;
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;
`;

export const Heading = styled(SectionHeading)`
    text-align:left;
    margin-bottom:30px;
`;


export const CustomTabList = styled(TabList)`
    margin:0;
    margin-bottom:20px;

    .react-tabs__tab{
        font-size: 13px;
        background: #ddd;
        margin-right: 10px;
        border: none;
        margin-bottom:10px;

        :focus{
            box-shadow:none;
        }
    }

    .react-tabs__tab--selected{
        background: #de9e53;
        border:none;
        border-radius:0px;
        color:#fff;

        :after{
            content: "";
            width: 0px;
            height: 0px;
            position: absolute;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-top: 15px solid #de9e53;
            bottom: -14px;
            left: calc(50% - 15px);
            background:transparent;

            @media ${device.mobileXL}{
                display:none;
            }
            
        }
    }
`;

export const PropertiesOuterWrapper = styled.div`
    position:relative;
`;

export const PropertiesLayout = styled.div`

`;

export const SpecLayout = styled.div`
    display:flex;
    align-items:center;
    margin-bottom:20px;

    p{
        text-transform:uppercase;
        margin-bottom:0px;
        margin-left:10px;
        line-height:1;
    }
`;

export const SpecIcon = styled.img`
    width:50px;
`;

export const SliderOuterWrapper = styled.div`
    position:relative;
    @media ${device.laptop}{
        margin-top:20px;
    }
`;

export const SliderLayout = styled.div`
    .propertyImg{
        height:400px;

        @media ${device.mobileXL}{
            height:350px;
        }
    }
`;

export const ButtonLayout = styled.div`
    display:flex;
    align-items:center;
    position:absolute;
    bottom:0px;
`;

export const LeftIcon = styled(ChevronLeft)`
    width:40px;
    height:40px;
    color:#fff;
`;

export const ImgButton = styled.button`
    line-height:0px;
    border:none;
    background:none;
    outline:none;
    background:#de9e53;
    padding: 5px 10px;

    :hover{
        background:#c39409;
    }
    :focus{
        outline:0;
    }
`;

export const RightIcon = styled(ChevronRight)`
    width:40px;
    height:40px;
    color:#fff;
`;