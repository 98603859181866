import React,{Component}from 'react';
import {TestimonialWrapper,Heading,TestimonialLayout,
    SliderOuterWrapper,SliderWrapper,TestimonialCard,TopLayout,
    ImageHolder,QuoteHolder,StyledIconOpen,TestimonialText,AuthorLayout,
    NextPrev,ImgButton,PrevImg,NextImg
} from './testimonials.style';
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GatsbyImage } from "gatsby-plugin-image";
import { StaticQuery, graphql } from 'gatsby';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Testimonials extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        console.log("PROPS"+JSON.stringify(this.props.TestimonialsData.TestimonialCards));
        const settings = {
            arrows:false,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                }
            ]
        };

        return (
            <TestimonialWrapper id="testimonialsSection">
                <Container>
                    <Heading>
                    {this.props.TestimonialsData.Heading} 
                    </Heading>
                    <TestimonialLayout>
                        <SliderOuterWrapper>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                            {
                                this.props.TestimonialsData.TestimonialCards.map((item,idx) => {
                                return (
                                    <SliderWrapper>
                                            <TestimonialCard>
                                                <ImageHolder>
                                                    <GatsbyImage
                                                        image={item.Img.childImageSharp.gatsbyImageData}
                                                        alt=""
                                                        className="authorImg" />
                                                </ImageHolder>
                                                <TopLayout>
                                                    <AuthorLayout>
                                                        <h5>
                                                        {item.Author} 
                                                        </h5>
                                                        <p>
                                                        {item.Designation} 
                                                        </p>
                                                    </AuthorLayout>
                                                    <QuoteHolder>
                                                        <StyledIconOpen />
                                                    </QuoteHolder>
                                                </TopLayout>
                                                <TestimonialText>
                                                    {item.TestimonialText} 
                                                </TestimonialText>
                                            </TestimonialCard>
                                        </SliderWrapper>
                                );
                                })
                            }
                            </Slider>
                        </SliderOuterWrapper>
                        <NextPrev>
                            <ImgButton onClick={this.previous} aria-label="Prev Button">
                                <PrevImg src={this.props.TestimonialsData.PrevImg} alt=""/>
                            </ImgButton>
                            <ImgButton onClick={this.next} aria-label="Next Button">
                                <NextImg src={this.props.TestimonialsData.NextImg} alt=""/>
                            </ImgButton>
                        </NextPrev>
                    </TestimonialLayout>
                </Container>
            </TestimonialWrapper>
        );
    }
}
export default () => (
    <StaticQuery
        query={graphql`{
  realestatePage4Json {
    Testimonials {
      Heading
      TestimonialCards {
        Img {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        TestimonialText
        Author
        Designation
      }
      PrevImg
      NextImg
    }
  }
}
`}
        render={(data) => (
            <Testimonials TestimonialsData={data.realestatePage4Json.Testimonials}/>
        )}
    />
  )