import styled from 'styled-components';
import {device} from '../Common/device';
import {SectionHeading} from '../Common/common.style';
import {QuoteLeft} from '@styled-icons/fa-solid/QuoteLeft';

export const TestimonialWrapper = styled.section`
    padding:100px 0px;
    background:#fafafa;

    @media ${device.tablet} {
        padding:80px 10px;
    }
`;

export const Heading = styled(SectionHeading)`
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const TestimonialLayout = styled.div`

`;

export const SliderOuterWrapper = styled.div`
    margin:0px -15px;
    position:relative;
    .slick-list{
        line-height:0;
    }
`;

export const SliderWrapper = styled.div`
    outline:0;
`;

export const TestimonialCard = styled.div`
    background:#fff;
    box-shadow: 0px 0px 15px 0px #ddd;
    margin:40px 15px 15px;
    padding:60px 30px 30px;
    position:relative;

    @media ${device.laptop} {
        flex-direction:column;
    }

`;
export const TopLayout = styled.div`
    display:flex;
    align-items:flex-start;
    justify-content: space-between;
    margin-bottom:20px;

    h5{
        text-align:left;
        margin-bottom:10px;
        line-height:1;
    }
`;

export const ImageHolder = styled.div`
    position:absolute;
    top:-40px;

    .authorImg{
        height:80px;
        width:80px;
        border-radius:100%;
    }
`;

export const QuoteHolder = styled.div`
    text-align:center;
`;

export const StyledIconOpen = styled(QuoteLeft)`
    height:40px;
    width:40px;
    color:#de9e53;
`;

export const AuthorLayout = styled.div`
    p{
        text-align:left;
        margin-bottom:0px;
        line-height:1;
    }
`;


export const TestimonialText = styled.p`
    margin-bottom:0px;
`;

export const NextPrev = styled.div`
    text-align:center;
`;

export const ImgButton = styled.button`
    border:none;
    background:none;
    outline:0;

    :focus{
        outline:0;
    }
`;

export const PrevImg = styled.img`
    margin:20px 0px 0px;
    width: 40px;
    cursor:pointer;
    transition:all .4s;
    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }
`;

export const NextImg = styled.img`
    margin:20px 0px 0px;
    width: 40px;
    cursor:pointer;
    transition:all .4s;
    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }
`;

