import styled from 'styled-components';
import {SectionHeading,Commonbtn} from '../Common/common.style';
import {device} from '../Common/device';
import {SocialFacebook} from '@styled-icons/typicons/SocialFacebook';
import {SocialTwitter} from '@styled-icons/typicons/SocialTwitter';
import {SocialLinkedin} from '@styled-icons/typicons/SocialLinkedin';
import {SocialInstagram} from '@styled-icons/typicons/SocialInstagram';
import {Row} from 'react-bootstrap';
import BackgroundImg from '../../../assets/realestate-4-images/map.jpg';

export const FooterSection = styled.section`
`;

export const FooterWrapper = styled.div`
    background-image:url(${BackgroundImg}) !important;
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    padding:100px 0px;

    @media ${device.tablet}{
        padding:80px 10px;
    }
`;

export const ContactFormLayout = styled.div`
    background-color:#fff;
    padding:40px;
`;

export const ContactHeading = styled(SectionHeading)`
    margin-bottom:30px;
    text-align:left;
`;

export const FormLayout = styled.div`
    
`;

export const Form = styled.form`
    margin-bottom:0px;
`;

export const FormGroup = styled.div`
    margin-bottom:30px;
`;

export const InputText = styled.input`
    width: 100%;
    border: none;
    border-bottom:1px solid #ccc;
    padding: 8px 0px;
    outline:0;
    font-size: 16px;
`;

export const ContactSpanErr = styled.span`
    color:red;
    font-size:14px;
`;

export const TextareaMessage = styled.textarea`
    width: 100%;
    border: none;
    border-bottom:1px solid #ccc;
    padding: 8px 0px;
    height:100px;
    outline:0;
    font-size: 16px;
`;

export const TextCenter = styled.div`
    text-align:center;
`;

export const SubmitBtn = styled(Commonbtn)`
    font-weight:bold;

    :hover{
        color:#fff;
        text-decoration:none;
    }
`;

export const ContactSpanSuccess = styled.span`
    color:green;
    font-size:14px;
`;

export const ContactInfoLayout = styled.div`
    padding:40px 0px;

    @media ${device.laptop}{
        padding:40px 0px 0px;
    }

    h5{
        color:#fff;
        text-align:left;
        margin-top:10px;
        line-height:1;
    }
`;

export const Heading = styled(SectionHeading)`
    color:#fff;
    text-align:left;
    margin-bottom:30px;
`;

export const ContactUsLayout = styled.div`
    
`;

export const DetailsLayout = styled.div`
    display:flex;
    align-items:center;
    margin-bottom:20px;

    P{
        color:#fff;
        margin-bottom:0px;
        margin-left:10px;
    }
`;

export const DetailsIcon = styled.img`
    width:40px;
`;


export const SocialLayout  = styled.div`
    
`;

export const FooterSocial = styled.a`
    margin-right:10px;
`;

export const FbIcon = styled(SocialFacebook)`
    width: 40px;
    color: #fff;
    background: rgba(255, 255, 255, .3);
    border-radius:100%;
    padding: 6px;
    margin:0px 5px 0px 0px;
    transition:all .5s;
    :hover{
        background:#3b5999;
        color:#fff;
    }
`;

export const TwitterIcon = styled(SocialTwitter)`
    width: 40px;
    color: #fff;
    background: rgba(255, 255, 255, .3);
    border-radius:100%;
    padding: 6px;
    margin:0px 5px 0px 0px;
    transition:all .5s;
    :hover{
        background:#00adee;
        color:#fff;
    }
`;

export const LinkedinIcon = styled(SocialLinkedin)`
    width: 40px;
    color: #fff;
    background: rgba(255, 255, 255, .3);
    padding: 6px;
    border-radius:100%;
    margin:0px 5px 0px 0px;
    transition:all .5s;
    :hover{
        background:#3b5999;
        color:#fff;
    }
`;

export const InstaIcon = styled(SocialInstagram)`
    width: 40px;
    color: #fff;
    background: rgba(255, 255, 255, .3);
    padding: 6px;
    border-radius:100%;
    margin:0px 5px 0px 0px;
    transition:all .5s;
    :hover{
        background:#ed008d;
        color:#fff;
    }
`;
export const CustomRow = styled(Row)`
    align-items:center;
`;

export const BottomFooterPara = styled.p`
    color:#000;
    margin-bottom:0px;
    padding:15px 0px;   
    text-align:left;
    font-size:15px;

    @media ${device.laptop}{
        text-align:center;
    }
`;

export const BottomLink = styled.a`
    margin-bottom:0px;
    padding:10px 0px;  
    color:#000;
    text-decoration:none;
    text-align:center;
    font-size:15px;   
    
    :hover{
        text-decoration:underline;
    }
`;

export const QuickLinksLayout = styled.div`
    
`;

export const FooterUl = styled.ul`
    text-align:right;
    margin:0;

    @media ${device.laptop}{
        text-align:center;
        margin-bottom:15px;
    }
`;

export const FooterLi = styled.li`
    list-style:none;
    display:inline-block;
    margin:0;
    margin-left:15px;

    a{
        color:#2f2f2f;
        text-decoration:none;

        :hover{
            text-decoration:underline;
        }
    }
`;


