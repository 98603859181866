import styled from 'styled-components';
import {device} from '../Common/device';
import {SectionHeading} from '../Common/common.style';
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export const AboutSection = styled.section`
    padding:100px 0px;

    @media ${device.tablet} {
        padding:80px 10px;
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;

    @media ${device.laptop} {
        flex-direction:column;
    }
`;

export const ImageHolder = styled.div`
    padding-right:10px;

    @media ${device.laptop} {
        padding-right:0px;
        margin-bottom:40px;
    }
`;

export const TextLayout = styled.div`
    padding-left:10px;

    @media ${device.laptop} {
        padding-left:0px;
    }
`;

export const Heading = styled(SectionHeading)`
    text-align:left;
    margin-bottom:30px;
`;

export const FeaturesLayout = styled.div`
    display:flex;
    align-items:center;
    margin-top:10px;
`;

export const FeaturesTextLayout = styled.div`
    margin-left:10px;

    h5{
        line-height:1;
        text-align:left;
        margin-bottom:5px;
    }
    p{
        line-height:1;
        margin-bottom:0px;
    }
`;

export const FeaturesImg = styled.img`
    width:50px;
    height:50px;

    @media ${device.mobileM} {
        width:40px;
        height:40px;
    }
`;



