import React,{Component} from 'react';
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {TeamSection,Heading,SliderOuterWrapper,TeamSliderWrapper,TeamSliderLayout,
    ImageLayout,TeamImgFigure,TeamDescWrapper,TeamDesc,TeamPhoneAnchor,
    SocialLayout,FbIcon,TwitterIcon,LinkedinIcon,InstaIcon,
    NextPrev,ImgButton,PrevImg,NextImg} from './team.style';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

class TeamPage extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render(){

        var settings = {
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                  breakpoint: 575,
                  settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        rows:1
                    }
                }
            ]
        };

        return (
            <TeamSection id="teamSection">
                <Container>
                    <Heading>
                        {this.props.TeamData.Heading}
                    </Heading>

                    <SliderOuterWrapper>
                        <Slider ref={c => (this.slider = c)}  {...settings}>
                        {
                            this.props.TeamData.TeamSlider.map((item,idx) => {
                            return (
                                <TeamSliderWrapper>
                                    <TeamSliderLayout>
                                        <ImageLayout>
                                            <TeamImgFigure>
                                                <GatsbyImage
                                                    image={item.TeamSliderImg.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    className="teamImg" />
                                            </TeamImgFigure>

                                            <SocialLayout>
                                                <FbIcon/>
                                                <TwitterIcon/>
                                                <LinkedinIcon/>
                                                <InstaIcon/>
                                            </SocialLayout>
                                        </ImageLayout>
                                        <TeamDescWrapper>
                                            <TeamDesc>
                                                <h5>{item.TeamName}</h5>
                                                <TeamPhoneAnchor href={"tel:"+item.TeamPhone}>
                                                    <p>{item.TeamPhone}</p>
                                                </TeamPhoneAnchor>
                                            </TeamDesc>
                                        </TeamDescWrapper>
                                    </TeamSliderLayout>

                                </TeamSliderWrapper>
                            );
                            })
                        }
                        </Slider>
                    </SliderOuterWrapper>
                    <NextPrev>
                        <ImgButton onClick={this.previous} aria-label="Prev Button">
                            <PrevImg src={this.props.TeamData.PrevImg} alt=""/>
                        </ImgButton>
                        <ImgButton onClick={this.next} aria-label="Next Button">
                            <NextImg src={this.props.TeamData.NextImg} alt=""/>
                        </ImgButton>
                    </NextPrev>
                </Container>
            </TeamSection>
        );
    }
}

const Team = () => (
    <StaticQuery
        query={graphql`{
  realestatePage4Json {
    Team {
      Heading
      TeamSlider {
        TeamSliderImg {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        TeamName
        TeamPhone
      }
      PrevImg
      NextImg
    }
  }
}
`}
        render={(data) => (
            <TeamPage TeamData={data.realestatePage4Json.Team}/>
        )}
    />
)
export default Team;