import React from 'react';
import {AboutSection,CustomRow,ImageHolder,TextLayout,Heading,
    FeaturesLayout,FeaturesTextLayout,FeaturesImg
} from './about.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby';

const About = () => {
    const JSONData  = useStaticQuery(graphql`{
  realestatePage4Json {
    About {
      Heading
      Description
      Img {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      FeaturesImg1
      FeaturesHeading1
      FeaturesDesc1
      FeaturesImg2
      FeaturesHeading2
      FeaturesDesc2
    }
  }
}
`);
    const AboutData = JSONData.realestatePage4Json.About;
    return (
        <AboutSection id="aboutSection">
            <Container>
                <CustomRow>
                    <Col lg={6}>
                        <ImageHolder>
                            <GatsbyImage image={AboutData.Img.childImageSharp.gatsbyImageData} alt="" />
                        </ImageHolder>
                    </Col>
                    <Col lg={6}>
                        <TextLayout>
                            <Heading>
                                {AboutData.Heading}
                            </Heading>
                            <p>
                            {AboutData.Description} 
                            </p>
                        
                            <Row>
                                <Col md={6} xs={6}>
                                    <FeaturesLayout>
                                        <FeaturesImg 
                                            src={AboutData.FeaturesImg1} 
                                            alt=""
                                        />
                                        <FeaturesTextLayout>
                                            <h5>
                                            {AboutData.FeaturesHeading1} 
                                            </h5>
                                            <p>
                                            {AboutData.FeaturesDesc1}
                                            </p>
                                        </FeaturesTextLayout>
                                    </FeaturesLayout>
                                </Col>
                                <Col md={6} xs={6}>
                                    <FeaturesLayout>
                                        <FeaturesImg 
                                            src={AboutData.FeaturesImg2} 
                                            alt=""
                                        />
                                        <FeaturesTextLayout>
                                            <h5>
                                            {AboutData.FeaturesHeading2} 
                                            </h5>
                                            <p>
                                            {AboutData.FeaturesDesc2}
                                            </p>
                                        </FeaturesTextLayout>
                                    </FeaturesLayout>
                                </Col>
                            </Row>
                        </TextLayout>
                    </Col>
                </CustomRow>
            </Container>
        </AboutSection>
    );
}

export default About;