import React,{Component} from 'react';
import {PropertiesSection,CustomRow,Heading,CustomTabList,
    PropertiesOuterWrapper,PropertiesLayout,SpecLayout,SpecIcon,
    SliderOuterWrapper,SliderLayout,ButtonLayout,ImgButton,LeftIcon,RightIcon
} from './ourproperties.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab, Tabs, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import { StaticQuery, graphql } from 'gatsby';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GatsbyImage } from "gatsby-plugin-image";

class PropertiesPage extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.state = {
            tabIndex: 0
        }
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
        };
        
        return (
            <PropertiesSection id="propertiesSection">
                <Container>
                    <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>
                        <CustomRow>
                            <Col lg={6}>
                                <Heading>
                                    {this.props.PropertiesData.Heading}
                                </Heading>
                                <p>{this.props.PropertiesData.Description}</p>

                                <CustomTabList>
                                {
                                    this.props.PropertiesData.TabList.map((item,idx) => {
                                        return <Tab>{item.Tab}</Tab>
                                    })
                                }
                                </CustomTabList>

                                {
                                    <PropertiesOuterWrapper>
                                        <PropertiesLayout>
                                            <Row>
                                                {
                                                    this.props.PropertiesData.TabList[this.state.tabIndex].SpecLayout.map((specItem, specIdx)=>{
                                                        return <Col md={6} sm={6}>
                                                            <SpecLayout>
                                                                <SpecIcon src={specItem.SpecIcon} alt=""/>
                                                                <p>
                                                                {specItem.SpecText} 
                                                                </p>
                                                            </SpecLayout>
                                                        </Col>
                                                    })
                                                }
                                            </Row>
                                        </PropertiesLayout>
                                    </PropertiesOuterWrapper>
                                }
                            </Col>

                            <Col lg={6}>
                            {
                                this.props.PropertiesData.TabList.map((itemTab,idxTab) => {
                                return <TabPanel>
                                        <SliderOuterWrapper>
                                            <Slider ref={c => (this.slider = c)} {...settings}>
                                            {
                                                itemTab.TabPanel.map((itemPanel,idxPanel) => {
                                                return (
                                                    <SliderLayout>
                                                        <GatsbyImage
                                                            image={itemPanel.Img.childImageSharp.gatsbyImageData}
                                                            alt=""
                                                            className="propertyImg" />
                                                    </SliderLayout>
                                                );
                                                    })
                                            }
                                            </Slider>

                                            <ButtonLayout>
                                                <ImgButton onClick={this.previous} aria-label="Prev Button">
                                                    <LeftIcon />
                                                </ImgButton>
                                                <ImgButton onClick={this.next} aria-label="Next Button">
                                                    <RightIcon />
                                                </ImgButton>
                                            </ButtonLayout>
                                        </SliderOuterWrapper>
                                    </TabPanel>
                                })
                            }
                            </Col>
                        </CustomRow>
                    </Tabs>
                </Container>
            </PropertiesSection>
        );
    }
}


export default () => (
<StaticQuery
    query={graphql`{
  realestatePage4Json {
    Properties {
      Heading
      Description
      TabList {
        Tab
        TabPanel {
          Img {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        SpecLayout {
          SpecIcon
          SpecText
        }
      }
    }
  }
}
`}

    render={(data) => (
        <PropertiesPage PropertiesData={data.realestatePage4Json.Properties}/>
    )}
/>
)